import React from "react"
import Layout from "../components/Layout"
import Link from "gatsby-link"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "../css/information.css"
import bookletLeft from "../images/Booklet-02.png"
import bookletRight from "../images/Booklet-03.png"
import bookletLeft_825 from "../images/Booklet-825-02.png"
import bookletRight_825 from "../images/Booklet-825-03.png"

import { useMediaQuery } from "react-responsive"

export default ({ data }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)"
  })
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)"
  })

  return (
    <Layout>
      <SEO title="Pages 2-3" />
      <StyledHero home="true" img={data.images1.childImageSharp.fluid}>

        {isBigScreen && (
          <>
            <Link to="/information">
              <img
                className="Page"
                src={bookletLeft_825}
                alt=""
                useMap="#Left"
              />
              <map name="Left">
                <area shape="rect" coords="0,535,824,0" />
              </map>
            </Link>
            <Link to="/information-3">
              <img
                className="Page"
                src={bookletRight_825}
                alt=""
                useMap="#Right"
              />
              <map name="Right">
                <area shape="rect" coords="0,1069,824,534" />
              </map>
            </Link>
          </>
        )}
        {!isBigScreen && isDesktopOrLaptop && (
          <>
            <Link to="/information">
              <img className="Page" src={bookletLeft} alt="" useMap="#Left" />
              <map name="Left">
                <area shape="rect" coords="0,535,824,0" />
              </map>
            </Link>
            <Link to="/information-3">
              <img className="Page" src={bookletRight} alt="" useMap="#Right" />
              <map name="Right">
                <area shape="rect" coords="0,1069,824,534" />
              </map>
            </Link>
          </>
        )}

      </StyledHero>
    </Layout>
  )
}
export const query = graphql`
  query {
    images1: file(relativePath: { eq: "serenity-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
